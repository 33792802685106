import React, { memo, useCallback, useMemo } from 'react';
import './AssetsList.less';
import { Button, Col, Layout, Row } from 'antd';
import { useAuth } from '@hooks/useAuth';
import UserAvatar from '@components/UserAvatar';
import { useResponsive } from '@hooks/useResponsive';
import AssetItem from '@pages/ExternalReviewer/AssetsList/AssetItem';
import { GetAssetReviewContentSuccessDto } from '@api/Api';
import moment from 'moment';

const { Header, Content } = Layout;

type _AssetsListProps = {
  content: GetAssetReviewContentSuccessDto | undefined;
};

export default memo(function AssetsList({ content }: _AssetsListProps) {
  const { isXsWidth } = useResponsive();
  const { user, logout, login } = useAuth();
  const onSignInOrSignOut = useCallback(() => {
    if (user) {
      logout({ localOnly: true });
      return;
    }

    const { pathname, search } = window.location;
    login({
      prompt: 'login',
      appState: { returnTo: `${pathname}${search}` }
    });
  }, [login, logout, user]);
  const campaignName = useMemo(() => {
    return content?.campaignName;
  }, [content?.campaignName]);
  const linkName = useMemo(() => {
    return (
      content?.name ??
      `Review Link - ${moment(content?.createdAt).format('MMM DD, YYYY')}`
    );
  }, [content?.createdAt, content?.name]);
  return (
    <Layout
      style={{ background: '#1A1C1E', color: '#fff', minHeight: '100vh' }}
    >
      <Layout
        className="site-layout external-reviewer-media"
        style={{ background: '#1A1C1E' }}
      >
        <Header
          className="site-layout-background b-background-black"
          style={{
            padding: 0,
            width: '100%'
          }}
        >
          <div className="media_viewer_header">
            <div className="media_viewer_header-asset-info">
              <Row gutter={16} wrap={false} align="middle">
                {user && (
                  <Col>
                    <UserAvatar
                      isActive
                      size={isXsWidth ? 32 : 'large'}
                      src={user.pictureUrl || ''}
                      userEmail={user.email}
                      userName={user.name}
                    />
                  </Col>
                )}
                <Col>
                  <div className="media_viewer_header-asset-info-name">
                    {linkName}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="media_viewer_actions">
              <Button
                type="primary"
                className="media_viewer_header-btn-primary"
                onClick={onSignInOrSignOut}
              >
                {user ? 'Sign out' : 'Sign in'}
              </Button>
            </div>
          </div>
        </Header>
        <Content style={{ marginTop: 70 }}>
          <div className="assets-list">
            <div className="assets-list-title">{campaignName}</div>
            <div className="assets-list-wrapper">
              {content?.assets.map((it) => {
                return <AssetItem key={it.id} asset={it} />;
              })}
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
});
