import React, { useEffect } from 'react';
import { Modal, Row, Col, Button, Form, Input, Divider } from 'antd';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@hooks';
import { toggleSingInExternalReviewerModal } from '@redux/actions/modalAction';
import { fullNameValidator } from '@helpers/validators';
import { useAssetReview } from '@components/AssetReviewProvider';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import './SingInExternalReviewerModal.less';
import { useAuth } from '@hooks/useAuth';
import { nonNull } from '@helpers/non-null';

function SingInExternalReviewerModal() {
  const dispatch = useDispatch();
  const singInExternalReviewerModal = useTypedSelector(
    ({ modal }) => modal.singInExternalReviewerModal
  );
  const { user, loginForReview, signUpForReview, isLoading, error } = useAuth();
  const { reviewId } = useAssetReview();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!user) return;
    if (!singInExternalReviewerModal?.visible) return;
    dispatch(
      toggleSingInExternalReviewerModal({
        visible: false
      })
    );
  }, [user, singInExternalReviewerModal?.visible, dispatch]);

  const onSignInForm = async ({ email }: { email: string }) => {
    loginForReview({ email, assetReviewId: nonNull(reviewId) });
  };

  const onSignUpForm = async ({
    email,
    fullName
  }: {
    email: string;
    fullName: string;
  }) => {
    if (!fullNameValidator(fullName)) {
      form.setFields([
        {
          name: 'fullName',
          errors: ['Please enter valid First and Last Name']
        }
      ]);
      return;
    }
    const firstName = fullName.split(' ').filter((x) => !!x)[0];
    const lastName = fullName.substring(firstName.length).trim();
    signUpForReview({
      email,
      firstName,
      lastName,
      assetReviewId: nonNull(reviewId)
    });
  };

  const onCloseModal = () =>
    dispatch(
      toggleSingInExternalReviewerModal({
        visible: false
      })
    );

  return (
    <Modal
      visible={singInExternalReviewerModal?.visible}
      footer={null}
      centered={true}
      onCancel={onCloseModal}
      destroyOnClose
      closeIcon={<CrossSvg />}
      width={472}
      className="sing-in-external-reviewer-modal"
    >
      {error && error.error === 'signup_required' ? (
        <div className="modal_container">
          <h2 className="modal_container__title">Enter your info</h2>
          <div className="modal_container__subtitle">
            To collaborate on the media that has been shared with you.
          </div>
          <Form form={form} onFinish={onSignUpForm} layout="vertical">
            <Form.Item name="email" style={{ display: 'none' }}>
              <Input placeholder="Enter email" size="large" type="email" />
            </Form.Item>
            <Form.Item
              name="fullName"
              rules={[{ required: true, message: 'Full name required.' }]}
            >
              <Input placeholder="Enter full name" size="large" type="text" />
            </Form.Item>
            <Divider />
            <div className="modal_container__subtitle bottom-text">
              Already have an account?{' '}
              <a href="/login" target="_blank">
                Sign in
              </a>{' '}
              to StreamWork.
            </div>
            <Row justify="end" gutter={20} className="row-bottom">
              <Col>
                <Button
                  type="text"
                  onClick={onCloseModal}
                  htmlType="button"
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Done
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <div className="modal_container">
          <h2 className="modal_container__title">Enter your email</h2>
          <div className="modal_container__subtitle">
            To access the media that has been shared with you.
          </div>
          <Form form={form} onFinish={onSignInForm} layout="vertical">
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Email required.' }]}
            >
              <Input placeholder="Enter email" size="large" type="email" />
            </Form.Item>
            <Row justify="end" gutter={20} className="row-bottom">
              <Col>
                <Button
                  type="text"
                  onClick={onCloseModal}
                  htmlType="button"
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Done
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </Modal>
  );
}

export default SingInExternalReviewerModal;
