import { AssetItemDto, AudioVideoMetadataDto } from '@api/Api';
import Loader from '@components/Loader';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import { formatDuration } from '@helpers/formatDuration';
import { nonNull } from '@helpers/non-null';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { setAsset, setAssetVariant } from '@redux/actions/mediaViewerAction';
import moment from 'moment';
import React, { memo, useMemo, useCallback } from 'react';

type AssetItemProps = {
  asset: AssetItemDto;
};

export default memo(function AssetItem({ asset }: AssetItemProps) {
  const dispatch = useTypedDispatch();
  const version = useMemo(() => {
    return asset.asset?.versions[0];
  }, [asset.asset?.versions]);

  const isAudioOrVideo = useMemo(() => {
    return (
      version?.metadata.type === 'audio' || version?.metadata.type === 'video'
    );
  }, [version]);
  const durationTag = useMemo(() => {
    if (!isAudioOrVideo) return;
    const meta = version?.metadata as AudioVideoMetadataDto;
    return formatDuration(
      meta.info.avInfo?.audio?.durationSeconds ||
        meta.info.avInfo?.video?.durationSeconds ||
        0
    );
  }, [version, isAudioOrVideo]);
  const preparePreview = useMemo(() => {
    const res = assetPreviewThumbnails({
      url: version?.metadata.preview?.url || '',
      status: version?.metadata.preview?.status || '',
      type: version?.metadata.type || '',
      format: 'img'
    });

    if (res === 'pending') return 'pending';
    if (typeof res === 'string') return <img src={res || ''} alt="" />;
    return res;
  }, [version]);
  const onAssetClick = useCallback(() => {
    const selectedAsset = nonNull(asset.asset).versions[0];
    dispatch(setAsset(asset));
    dispatch(setAssetVariant(selectedAsset.id));
  }, [asset, dispatch]);
  return (
    <div className="asset-item-wrapper" onClick={onAssetClick}>
      <div className="asset-item-wrapper__preview">
        {preparePreview === 'pending' && (
          <Loader
            classNameWrapper="assets-loader-wrapper"
            spinSize={40}
            isSpinning={true}
          />
        )}
        <div>{preparePreview !== 'pending' && preparePreview}</div>
        {isAudioOrVideo && (
          <div className="asset-item-wrapper__preview--time-tag">
            {durationTag}
          </div>
        )}
      </div>
      <div className="asset-item-wrapper-info">
        <div className="asset-item-wrapper-info__title">
          {version?.name}.{version?.extension}
        </div>
        <div className="asset-item-wrapper-info__description">
          Edited {moment(asset.modifiedAt).fromNow()}
        </div>
      </div>
    </div>
  );
});
