import React, { useCallback, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout, message } from 'antd';
import MediaViewerHeader from '@components/MediaViewerHeader';
import { useDispatch } from 'react-redux';
import {
  createNewComment,
  removeComment,
  editComment,
  setCommentLike,
  getAssetInternalComments,
  setAssetVariant,
  setAsset
} from '@redux/actions/mediaViewerAction';
import {
  addNotificationSubscription,
  addReviewVisit,
  createCommentAssetReview,
  createReplyCommentAssetReview,
  deleteCommentAssetReview,
  getAssetReview,
  getCommentAssetReview,
  getCommentsAssetReview,
  getPotentialMentionsAssetReview,
  setCommentLikeAssetReview,
  updateCommentAssetReview
} from '@api/AssetReview';
import {
  ReplyAssetReviewCommentDto,
  CreateAssetReviewCommentDto,
  UpdateAssetReviewCommentDto,
  SetAssetReviewCommentLikeDto,
  PageDto,
  AssetVersionCommentMentionDto
} from '@api/Api';
import { useAssetReview } from '@components/AssetReviewProvider';
import { useTypedSelector } from '@hooks';
import { nonNull } from '@helpers/non-null';
import {
  useWebSocketDebouncedSubscription,
  useWebSocketSubscription
} from '@hooks/useWebSocketClient';
import { MediaViewerActionTypes } from '@redux/types/mediaViewerType';
import AccessClose from '@pages/ExternalReviewer/AccessClose';
import { ExternalReviewerPage } from '@pages/ExternalReviewer/ExternalReviewer';
import { useAuth } from '@hooks/useAuth';
import { apiClient } from '@api/client';

const { Header, Content } = Layout;

function ExternalReviewerMediaLayout() {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const selectedVersion = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion
  );
  const asset = useTypedSelector(({ mediaViewer }) => mediaViewer.assets);
  const { reviewId, password, updateExternalContent, isPreview } =
    useAssetReview();

  useEffect(() => {
    if (isPreview) return;
    if (user)
      addNotificationSubscription({
        reviewId: nonNull(reviewId),
        password
      });
    addReviewVisit({
      reviewId: nonNull(reviewId),
      password
    });
  }, [user, reviewId, password, isPreview]);

  const outletContext = useMemo(() => {
    const params = {
      assetReviewId: nonNull(reviewId),
      assetReviewPassword: password
    };
    return {
      addNewComment: async (
        data: CreateAssetReviewCommentDto | ReplyAssetReviewCommentDto,
        parentId: string
      ) => {
        try {
          let newComment = null;
          if (parentId && parentId !== 'new') {
            const {
              data: { comment }
            } = await createReplyCommentAssetReview({
              ...(data as ReplyAssetReviewCommentDto),
              ...params
            });
            newComment = comment;
          } else {
            const {
              data: { comment }
            } = await createCommentAssetReview({
              ...(data as CreateAssetReviewCommentDto),
              ...params
            });
            newComment = comment;
          }
          dispatch(createNewComment(newComment));
          return newComment;
        } catch (error: any) {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
          throw error;
        }
      },
      removeComment: async (id: string) => {
        try {
          await deleteCommentAssetReview({ id, ...params });
          dispatch(removeComment(id));
        } catch (error: any) {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
        }
      },
      editComment: async (data: UpdateAssetReviewCommentDto) => {
        try {
          const {
            data: { comment }
          } = await updateCommentAssetReview({
            ...data,
            ...params
          });
          dispatch(editComment(comment));
        } catch (error: any) {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
        }
      },
      setCommentLike: async (data: SetAssetReviewCommentLikeDto, user: any) => {
        try {
          await setCommentLikeAssetReview({
            ...data,
            ...params
          });
          dispatch(setCommentLike(data, user));
        } catch (error: any) {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
        }
      },
      getPotentialMentions: async ({
        assetVersionId,
        searchQuery
      }: {
        assetVersionId: string;
        searchQuery?: string;
      }): Promise<AssetVersionCommentMentionDto[]> => {
        const {
          data: { edges }
        }: {
          data: PageDto & { edges: { node: AssetVersionCommentMentionDto }[] };
        } = await getPotentialMentionsAssetReview({
          assetVersionId,
          searchQuery,
          ...params
        });
        if (edges) {
          const list = edges.map((item) => item.node);
          return list;
        }
        return [];
      },
      getPotentialGroupMentions: async ({
        assetVersionId,
        searchQuery
      }: {
        assetVersionId: string;
        searchQuery?: string;
      }): Promise<AssetVersionCommentMentionDto[]> => {
        const {
          data: { edges }
        }: {
          data: PageDto & { edges: { node: AssetVersionCommentMentionDto }[] };
        } =
          await apiClient.assetReview.assetReviewCommentsControllerListCommentPotentialGroupMentions(
            {
              assetVersionId,
              searchQuery,
              ...params
            }
          );
        if (edges) {
          const list = edges.map((item) => item.node);
          return list;
        }
        return [];
      }
    };
  }, [dispatch, reviewId, password]);

  const getCommentsList = useCallback(
    async (assetVersionId: string) => {
      try {
        const {
          data: { comments }
        } = await getCommentsAssetReview({
          assetVersionId,
          assetReviewId: nonNull(reviewId),
          assetReviewPassword: password
        });
        dispatch(getAssetInternalComments(comments));
      } catch (error: any) {
        message.error({
          content: 'Something went wrong',
          className: 'message-dark-modal'
        });
      }
    },
    [reviewId, dispatch, password]
  );

  useEffect(() => {
    if (!selectedVersion?.id) return;
    getCommentsList(selectedVersion.id);
  }, [selectedVersion?.id, getCommentsList]);

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.assetId !== asset?.id) return false;
    if (msg.payload.assetVersionId !== selectedVersion?.id) return false;
    if (msg.causedByMe) return;
    const {
      data: { comment }
    } = await getCommentAssetReview({
      id: msg.payload.commentId,
      assetReviewId: nonNull(reviewId),
      assetReviewPassword: password
    });
    dispatch(createNewComment(comment));
  }, 'asset-comment-created');

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.assetId !== asset?.id) return false;
    if (msg.payload.assetVersionId !== selectedVersion?.id) return false;
    if (msg.causedByMe) return;

    dispatch(removeComment(msg.payload.commentId));
  }, 'asset-comment-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.assetId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];

      const {
        data: { comment }
      } = await getCommentAssetReview({
        id: lastMessage.payload.commentId,
        assetReviewId: nonNull(reviewId),
        assetReviewPassword: password
      });
      dispatch({
        type: MediaViewerActionTypes.UPDATE_ASSET_INTERNAL_COMMENT,
        payload: comment
      });
    },
    1000,
    (msg) => {
      if (msg.payload.assetId !== asset?.id) return false;
      if (msg.payload.assetVersionId !== selectedVersion?.id) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'asset-comment-updated'
  );

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.assetId,
    async () => {
      try {
        const { data } = await getAssetReview({
          assetId: nonNull(asset?.id),
          reviewId: nonNull(reviewId),
          password
        });
        dispatch(setAsset(data.asset));
        dispatch(setAssetVariant(data.asset.asset?.versions[0].id ?? null));
        updateExternalContent(data.asset);
      } catch {
        window.location.reload();
      }
    },
    1000,
    (msg) => {
      if (msg.payload.assetId !== asset?.id) return false;
      if (msg.causedByMe && msg.payload.reason !== 'conversion-status-changed')
        return false;

      return true;
    },
    'asset-updated'
  );

  if (
    selectedVersion?.approvalWorkflow &&
    !selectedVersion.approvalWorkflow.isFinished &&
    !selectedVersion.approvalWorkflow.isPaused
  )
    return (
      <ExternalReviewerPage
        element={
          <AccessClose type="review" reviewReason="approval-in-progress" />
        }
      />
    );

  return (
    <Layout
      style={{ background: '#1A1C1E', color: '#fff', minHeight: '100vh' }}
    >
      <Layout
        className="site-layout external-reviewer-media"
        style={{ background: '#1A1C1E' }}
      >
        <Header
          className="site-layout-background b-background-black"
          style={{
            padding: 0,
            width: '100%'
          }}
        >
          <MediaViewerHeader isExternalReviewer={true} />
        </Header>
        <Content style={{ marginTop: 70 }}>
          <Outlet context={outletContext} />
        </Content>
      </Layout>
    </Layout>
  );
}

export default ExternalReviewerMediaLayout;
