import React, { ReactElement } from 'react';
import LinkProtected from '@pages/ExternalReviewer/LinkProtected';
import './ExternalReviewer.less';
import { ReactComponent as LogoSvg } from '@assets/icons/logo.svg';
import {
  AssetReviewProvider,
  useAssetReview
} from '@components/AssetReviewProvider';
import AccessClose from '@pages/ExternalReviewer/AccessClose';
import { Route, Routes } from 'react-router-dom';
import SingInExternalReviewerModal from '@components/Modals/SingInExternalReviewerModal';
import MediaViewer from '@pages/MediaViewer';
import ExternalReviewerMediaLayout from '@layouts/ExternalReviewerMediaLayout';
import { WebSocketClientProvider } from '@hooks/useWebSocketClient';
import { useAuth } from '@hooks/useAuth';
import ExpiredLink from '@components/ExpiredLink';
import { useTypedSelector } from '@hooks';
import AssetsList from '@pages/ExternalReviewer/AssetsList/AssetsList';

interface ExternalReviewerProps {
  element: ReactElement;
}

export function ExternalReviewerPage({ element }: ExternalReviewerProps) {
  return (
    <div className="external_reviewer_container">
      <div className="external_reviewer_top">
        <LogoSvg />
      </div>
      <div className="external_reviewer_content">{element}</div>
      <div className="external_reviewer_bottom">
        New to StreamWork? Create your <a href="/login">own account</a> and
        start collaborating.
      </div>
    </div>
  );
}

interface ExternalReviewerContainerProps {
  loader: ReactElement;
}

function ExternalReviewerContainer({ loader }: ExternalReviewerContainerProps) {
  const {
    isLoading,
    password,
    passwordRequired,
    passwordDoesNotMatch,
    isDisabled,
    setPassword,
    content,
    isExpired,
    commentMentions
  } = useAssetReview();
  const asset = useTypedSelector((state) => state.mediaViewer.assets);

  if (isExpired) return <ExpiredLink />;
  if (passwordRequired && passwordDoesNotMatch) {
    return (
      <ExternalReviewerPage
        element={
          <LinkProtected
            password={password}
            passwordDoesNotMatch={passwordDoesNotMatch}
            setPassword={setPassword}
            loading={isLoading}
          />
        }
      />
    );
  }

  if (isLoading) return loader;
  if (isDisabled) {
    return (
      <ExternalReviewerPage
        element={<AccessClose type="review" reviewReason="review-disabled" />}
      />
    );
  }

  if (!asset) {
    return <AssetsList content={content} />;
  }

  return (
    <>
      <Routes>
        <Route path="/*" element={<ExternalReviewerMediaLayout />}>
          <Route
            index
            element={<MediaViewer commentMentions={commentMentions} />}
          />
        </Route>
      </Routes>
      <SingInExternalReviewerModal />
    </>
  );
}

export default function ExternalReviewer(
  props: ExternalReviewerContainerProps
) {
  const { isAuthenticated, isLoading } = useAuth();
  if (!isAuthenticated && isLoading) return props.loader;
  return (
    <WebSocketClientProvider>
      <AssetReviewProvider type="share">
        <ExternalReviewerContainer {...props} />
      </AssetReviewProvider>
    </WebSocketClientProvider>
  );
}
